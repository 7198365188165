@import url('assets/fonts/fonts.css');
@import url('assets/icons/icons.css');
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/ngx-spinner/animations/ball-scale-multiple.css';

.material-icons {
  font-family: 'Material Icons' !important;
}

* {
  font-family: "Baloo Tammudu 2", "Calibri", sans-serif;
}

:root {

  --main-bg-color: #dbdbdb;

  --main-bg-lighter-color: #f3f3f3;
  --error-color: #d90052;
  --mdc-theme-error: #d90052 !important;
  --accent-green: #2C8737;
  --light-red: #d90052;
  --main-text-color: #242e66;
  --shaded-area-width: 70%;
  --shaded-area-min-width: 900px;
  --header-height: 6rem;
  --opaque-white: rgba(255, 255, 255, 0.5);
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-text-field-input-text-color: #242e66;
  --mat-select-panel-background-color: #fff !important;
  --mdc-filled-button-label-text-color: #fff !important;
  --mat-expansion-header-indicator-color: #242e66 !important;
  --mdc-icon-button-icon-size: 24px !important;
  --mdc-icon-button-state-layer-size: 30px !important;
  --mat-option-label-text-color: #242e66 !important;
  --mdc-filled-text-field-caret-color: #242e66 !important;
  --mdc-filled-text-field-focus-active-indicator-color: #242e66 !important;
  --mdc-filled-text-field-focus-label-text-color: #242e66 !important;
  --mat-option-selected-state-label-text-color: #242e66 !important;
  --mat-select-placeholder-text-color: #242e66 !important;
  --form-input-height: 3.5rem;
  --text-size-normal: 1rem;
  --mat-expansion-container-text-size: var(--text-size-normal) !important;
  --text-size-large: 1.125rem;
  --input-border-radius: 10px;
  --mat-bottom-sheet-container-text-color: #242e66 !important;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: rgb(220 221 230) !important;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--accent-green) !important;
}

html {
  font-size: 16px;
  --mdc-checkbox-selected-hover-state-layer-color:var(--accent-green)!important;
  --mdc-checkbox-selected-pressed-state-layer-color:var(--accent-green)!important;
}

body {


  color: #242e66;
  background-color: #fff;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 760px) {
  html {
    font-size: 22px;
  }
}


@media screen and (min-width: 860px) {
  html {
    font-size: 20px;
  }
}

body.modal-open {
  overflow: hidden;
}

.custom-no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.custom-darker-modal-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.custom-cookie-backdrop {
  background: rgba(0, 0, 0, 0);
}

.custom-install-banner-backdrop {
  opacity: 0;
}

.btn-primary,
.btn-primary:visited {
  display: block;
  margin: auto;
  width: 11rem;
  height: 2.6rem;
  border-radius: 100px;
  border: solid 2px var(--accent-green) !important;
  background-color: var(--accent-green) !important;


  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 2.2rem;
  transition: all 0.2s;
}


.btn-primary:focus {
  outline: 2px solid black;
  outline-offset: 2px;
}

.btn-primary:active {
  border: #2f9d43 !important;
  background-color: #2f9d43 !important;
  -webkit-box-shadow: 0 0 0 0.2rem #2f9d43 !important;
  box-shadow: 0 0 0 0.2rem #2f9d43 !important;
}

.btn-primary:disabled {
  border: solid 2px var(--accent-green) !important;
  background-color: var(--accent-green) !important;
  transition: all 0.2s;
}

.btn-skip,
.btn-skip:visited,
.btn-skip:focus {
  display: block;
  margin: auto;
  width: 33vw;
  height: 8.5vw;
  border-radius: 10px;
  background-color: rgba(36, 46, 102, 0.05);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -0.24px;
  text-align: center;
  color: #242e66;
}

.btn-skip:active {
  background-color: rgba(36, 46, 102, 0.2);
}

.btn-skip:disabled {
  background-color: rgba(36, 46, 102, 0.05);
}

.btn-nav {
  display: block;
  margin: auto;
  width: 11rem;
  height: 2.6rem;
  border-radius: 100px;
  background-color: #f7f7f7 !important;


  border: solid 2px #f7f7f7 !important;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2rem;
  letter-spacing: normal;
  text-align: center;

  color: #242e66 !important;
  text-transform: uppercase;
  transition: all 0.2s;
}

.btn-nav:hover, .btn-nav:focus {
  color: #ffffff !important;


  background-color: #242e66 !important;
  border-color: #242e66 !important;
}

.bottom-fixed-button-container {
  margin-top: 4rem;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4rem;
}

.bottom-fixed-button-inner {
  background-color: var(--opaque-white);
  width: var(--shaded-area-width);
  min-width: var(--shaded-area-min-width);
  display: flex;
  justify-content: center;
}

.component-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

.message-box {
  position: absolute;
  right: 5vw;
}

.icon-image {
  width: 2.8em;
  -o-object-fit: contain;
  object-fit: contain;
}

.box-circle {
  overflow: hidden;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blue-background {
  background: radial-gradient(circle, #ffffff 60%, #242e66 80%);
}

.box-circle img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 2.4rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.form-control {
  color: #242e66;
  display: block;
  margin: auto;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  width: 85vw;
  height: 3.5em;
  border: white;
  font-size: 1rem;
  letter-spacing: -0.24px;
  padding-left: 1.45rem;
  padding-bottom: 0;
}

input {
  border: white !important;
}

textarea {
  border: white !important;
  resize: none;
}

textarea.form-control {
  height: 9.5rem;
  line-height: 2.1 !important;
  font-size: 1rem;
  padding: 1rem 1.45rem
}

.input-group-prepend {
  width: 17%;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.input-group-text {
  border-radius: 10px 0 0 10px;
  width: 100%;
  justify-content: center;
  padding-top: 20%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
  margin: 0 0 0 -1px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  border: 0 solid;
  background-color: #fff;
  box-shadow: 10px 10px 30px 0 rgb(126 127 128 / 16%);
  color: #242e66;
}

.header-text,
.text-center,
.text-left,
.bold-center,
.bold-left,
.bold-left-margin,
.info-text,
.text-left-margin {
  font-size: 1rem;
  color: #242e66;
}

.bold-center,
.bold-left,
.bold-left-margin,
.header-text {
  font-weight: 700;
}

.bold-center,
.text-center {
  text-align: center;
}

.login-page-text-container {
  margin-left: 12vw;
  margin-right: 12vw;
  text-align: justify;
  font-size: 16px;
}

.bold-left,
.text-left,
.bold-left-margin {
  text-align: left;
}

.bold-left-margin,
.text-left-margin {
  margin-left: 1.45rem;
}

.text-left-margin {
  overflow-wrap: break-word;
}

.header-text {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 2em;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
  letter-spacing: -0.27px;
  text-transform: uppercase;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
}

.info-text {
  margin-left: 8vw;
  margin-right: 8vw;
  text-align: justify;
}

.back-text {
  margin-left: 5vw;
  color: var(--accent-green);
  font-size: 1rem;
  font-weight: 900;
}

.title-left {
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.27px;
  text-align: left;
  text-transform: uppercase;
}


.title-center {
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.27px;
  text-align: center;
  text-transform: uppercase;
}

.bold-right {
  font-weight: 700;
  font-size: 1rem;
  text-align: right;
}

.text-right {
  font-size: 1rem;
  text-align: right;
  width: 110%;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.header-icon-container,
.left-icon-container {
  opacity: 1;
  height: 1.3rem;
  margin: -8% -10%;
}

.desktop-header-icon-container {
  padding: 0 0 0 1rem;
  right: 25vw;
}

.left-icon-container {
  left: 5vw;
  right: auto;
}

.header-with-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2em;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: bold;


}

.no-bottom-margin {
  margin-bottom: 0;
}

@media (max-width: 360px) {

  .header-with-close,
  .header-text {
    margin-top: 10vw;
    height: auto;
  }

  .back-text {
  }
}

@media (min-width: 361px) {

  .header-with-close,
  .header-text,
  .back-text {
    margin-top: 3rem;
    height: auto;
  }
}

@media only screen and (max-width: 320px) {

  .header-with-close,
  .header-text {
    margin-top: 14vw;
    padding-top: 2rem;
  }

  .back-text {
  }
}

.header-title {
  height: auto;
  font-size: 1.125rem;
  padding: 0.5rem 0;
  margin: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--main-text-color);
}

.close {
  margin-left: 2vw;
  margin-top: 0;
  width: auto;
  opacity: 1;
  height: 100%;
  padding: 0.3em 0;
}

.header-img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 1.3rem;
}

.new-prescription-img {
  height: 1.431rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.svg-image {
  width: 9vw;
}

.show {
  opacity: 1 !important;
}

.step {
  opacity: 0;
  -webkit-transition: 0.8s ease-in-out all;
  -o-transition: 0.8s ease-in-out all;
  transition: 0.8s ease-in-out all;
}

.not-selected {
  border: 2px solid white;
}

.selected {
  border: 2px solid #242e66 !important;
}

.input-image {
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 0.35rem;
}

.input-image-symptom {
  height: 1.95rem;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 0.85rem;
}

.text-center-symptom {
  line-height: 1;
  font-size: 0.9rem;
  color: var(--main-text-color);
}

.calendar-image {
  height: 1.625em;
  -o-object-fit: contain;
  object-fit: contain;
}

.set-svg {
  margin: -0.5em 0 0 0 !important;
}

.break {
  height: 1rem;
}

.break2x {
  height: 2rem;
}

.break3x {
  height: 3rem;
}

.break4x {
  height: 4rem;
}

input[type="radio"] {
  display: none;
}

input[type="checkbox"] {
  display: none;
}

::-webkit-input-placeholder {
  font-size: 1rem;
}

::-moz-placeholder {
  font-size: 1rem;
}

:-ms-input-placeholder {
  font-size: 1rem;
}

::-ms-input-placeholder {
  font-size: 1rem;
}

::placeholder {
  font-size: 1rem;
  color: #746f70 !important;
}

@-webkit-keyframes slide-top {
  0% {
    transform: translateY(20vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(20vh);
  }

  100% {
    transform: translateY(0);
  }
}

.overflow-fix {
  overflow: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.error-msg, .error-msg-custom {
  padding-top: 0.9rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.8125rem;
  color: var(--light-red);
  text-align: right;
}

.error-msg-login {
  margin-bottom: 0;
  height: 0;
  font-weight: bold;
  font-size: 0.8125rem;
  color: var(--light-red);
  text-align: right;
}

input.ng-valid ~ .error-msg,
input.ng-untouched ~ .error-msg,
textarea.ng-valid ~ .error-msg,
textarea.ng-untouched ~ .error-msg {
  display: none;
}

input.ng-valid ~ .error-container > .error-msg,
input.ng-untouched ~ .error-container > .error-msg,
textarea.ng-valid ~ .error-container > .error-msg,
textarea.ng-untouched ~ .error-container > .error-msg {
  display: none;
}

.error-text {
  width: 100%;
  margin-top: 2vw;
  margin-right: 8vw;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.33;
  text-align: right;
  color: var(--light-red);
}

.link-color {
  color: #242e66 !important;
  cursor: pointer;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid var(--light-red) !important;
}

input.ng-invalid.ng-touched.mat-datepicker-input {
  border: 0 !important;
}

app-datepicker .mat-form-field-invalid {
  border: 0 !important;
}

app-datepicker input.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched {
  border: none !important;
}

.visit-type {
  display: flex;
  flex-direction: row;
  margin: 2vh auto;
  flex-wrap: wrap;
  justify-content: space-around;
}

.visit-type > button {
  width: 9.5rem;
  height: 5.5rem;
  border-radius: 10px;
  margin: 0.3rem 0.3rem;
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  background-color: white;
}

.visit-type > label {
  width: 8.5rem;
  height: 5.5rem;
  border-radius: 10px;
  margin: 0.3rem 0.3rem;
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  background-color: white;
}

.visit-type img {
  display: block;
  margin: 0 auto auto;
}

.symptom-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 48% 4% 48%;
  grid-template-columns: 48% 48%;
  grid-gap: 2vw 4%;
  user-select: none;
}

.desktop-symptom-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 48% 4% 48%;
  grid-template-columns: 48% 48%;
  grid-gap: 1vw 4%;
  user-select: none;
}

.symptom-list > button,
.desktop-symptom-list > button,
.whom-needed > label,
.two-button-grid > label {
  width: 100%;
  height: 6rem;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  background-color: white;
  -ms-grid-column-align: center;
  justify-self: center;

}

@media (min-width: 320px) {

  .symptom-list > button,
  .whom-needed > label,
  .two-button-grid > label {
    height: 5.2rem !important;
  }
}

@media (min-width: 440px) {

  .symptom-list > button,
  .whom-needed > label,
  .two-button-grid > label {
    height: 4rem;
  }
}

@media (min-width: 760px) {

  .symptom-list > button,
  .whom-needed > label,
  .two-button-grid > label {
    height: 4.5rem;
  }

  .symptom-list img {
    display: block;
    margin: auto;
  }

  .symptom-list .text-center {
    padding-top: 8px;
  }
}

.notification-item mat-expansion-panel,
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: #f9f9f9;
  color: #242e66;
  font-size: 1rem;
}

.notification-item .mat-expansion-panel-header {
  font-size: 1rem;
}

.delete-button {
  right: -60px;
  position: absolute !important;
  height: 69px !important;
  background-color: var(--light-red) !important;
  border-radius: 10px !important;
  padding: 0 !important;
  min-width: auto !important;
  -webkit-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.delete-button-img {
  width: 0;
  -webkit-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.notification-item .mat-expansion-panel-body {
  padding: 0.5rem 0 0;
  font-size: 1rem;
}

.load-more {
  background-color: rgba(36, 46, 102, 0.05);
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.7rem;
  margin-bottom: 10vh;
}

.load-more > p {
  height: 1.7rem;
  font-size: 1rem;
  padding: 0.35rem 0;
  margin: 0 1.5rem;
}

.radio-selection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70vw;
  max-width: 500px;
  font-size: 1rem;
  height: 2.9rem;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  background-color: white;
  color: #242e66;
  margin-top: 1rem;
  padding-top: 0.7rem;
  user-select: none;
}

.picture-preview-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 1.45rem;
}

.review-text-with-picture-container {
  margin-left: 1.45rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: flex-start;
}

.review-text-with-picture-container img {
  height: 1.6em;
}

.text-left-margin.with-picture {
  margin-left: 10vw;
  margin-top: 0.4em;
  position: absolute;
}

@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}

.button-with-icon {
  width: 33vw;
  height: 5rem;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-with-icon-text {
  -ms-transform: translateY(1.5vh);
  transform: translateY(1.5vh);
  font-size: 0.9rem;
  line-height: 1.2;
}

.button-with-icon img {
  display: block;
}

.center-element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

input[type="file"] {
  display: none;
}

.preview {
  display: inline-block;
  position: relative;
  margin: 0 0.9em 0.5em 0;
}

.preview .close {
  position: absolute;
  top: -0.4em;
  right: -0.25em;
  width: 1em;
  height: auto;
}

@media (max-width: 299px) {
  .smaller-text {
    -ms-transform: translateY(0.5vh);
    transform: translateY(0.5vh);
    line-height: 0.9;
  }
}

@media (min-width: 900px) {
  .desktop-padding {
    padding-left: 15%;
    padding-right: 15%;
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }

  .desktop-padding-max {
    padding-left: 1%;
    padding-right: 1%;
    margin-top: 10%;
  }

  .desktop-shrinker {
    -ms-transform: scale(0.85);
    transform: scale(0.85);
    overflow: hidden;
  }

  .desktop-shrinker-mid {
    margin-top: -7%;
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    overflow: hidden;
  }

  #global-footer {
    background: none !important;
  }

  .img-rectangle {
    -o-object-position: center;
    object-position: center;
    padding-top: 6%;
  }

  .desktop-img-rectangle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}


.mat-expansion-panel-header:not([aria-disabled=true]) {
  background: white !important;
  color: #242e66 !important;
  padding: 0 5%;
}

.mat-expansion-panel-body {
  background: white !important;
  color: #242e66 !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  color: #242e66 !important;
  font-size: 1rem;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.mat-expansion-panel {
  background: white !important;
}

.mat-expansion-panel-header {
  min-height: 4.375rem !important;
  height: fit-content !important;
}

.mat-expansion-panel-header:focus {
  outline: 2px solid black !important;
  outline-offset: -3px;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: #242e66;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 0 0 10px 0 rgba(126, 127, 128, 0.5) !important;
  box-shadow: 0 0 10px 0 rgba(126, 127, 128, 0.5) !important;
}


.header-row-spacing-even {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: 0;
  margin-left: 0;
  text-align: center;
}

.header-row-spacing-start {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 0;
  margin-left: 0;
  text-align: center;
}

.mat-content {
  display: block !important;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.bp-date {
  font-size: 1rem;
  line-height: 0.25;
  letter-spacing: -0.24px;
  text-align: left;
  padding-top: 1.4em;
  min-width: 4rem;
}

.bp-time {
  font-size: 0.6rem;
  line-height: 2;
  letter-spacing: -0.15px;
  text-align: left;
}


.circle {
  z-index: 2;
  display: inline-table;
  vertical-align: middle;
  width: 1.9rem;
  height: 1.9rem;
  background-color: #bbb;
  border-radius: 50%;
}

.circle__content {
  display: table-cell;
  text-align: center;
  font-weight: bold;
  color: #fff;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 1rem;
  line-height: 2.4rem;
}

.purple-circle {
  background-color: #aa57de;
  border-radius: 50%;
  margin-right: -1%
}

.teal-circle {
  background-color: #47cef7;
  border-radius: 50%;
}

.blue-circle {
  background-color: #242e66;
  border-radius: 50%;
  margin-left: -1%;
}

.white-circle {
  border-radius: 50%;
  border: solid 2px #242e66;
  background-color: #fff;
  color: #242e66 !important;
  font-weight: 500 !important;
}

.ws-padding {
  padding-right: 10% !important;;
}

.circle-padding {
  padding: 0 6% 0 0 !important;
  display: contents;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}


.line {
  z-index: 1;
  border-bottom: 20px solid #e2e2e2;
  margin: 0 -10% 0 -10%;
  -webkit-animation: line 0.3s ease-in-out forwards;
  animation: line 0.3s ease-in-out forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}


.line-with-numbers {
  border-bottom: 16px solid #e2e2e2 !important;
  margin: 0;
  width: 50vw !important;
  border-radius: 10px;
}

.desktop-line-with-numbers {
  width: 100% !important;
  border-radius: 10px;
  height: 1rem !important;
  background-color: #e2e2e2;
  position: relative;
}

.desktop-line-label-container {
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
}

.line-labels {
  font-weight: bold;
  font-size: 0.8rem;

  align-content: center;
  color: #ffffff;
}

.desktop-line-label {
  font-weight: bold;
  font-size: 0.8rem;
  color: #ffffff;
  text-align: start;
}

.line-label-container {
  width: inherit;
  position: absolute;

  align-content: center;
  z-index: 1;
  margin-top: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
}

.display-contents {
  display: contents;
}

.purple-circle-single {
  background-color: #aa57de;
  border-radius: 50%;
  margin: -0.5em 0 0 0;
  position: absolute;
}

@-webkit-keyframes line {
  from {
    width: 0;
  }

  to {
    width: calc(55% - 100px);
  }
}

@keyframes line {
  from {
    width: 0;
  }

  to {
    width: calc(55% - 100px);
  }
}

.item-flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 0 !important;
  padding: 0 15px !important;
}

.item-header {
  font-size: 1rem;
  font-weight: bold;
}

.item-subtitle {
  font-size: 1rem;
}


.diary-nav {
  max-height: 1.75rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.connect-device {
  height: 1.75rem;
  width: 1.75rem;
}

.arrow-back {
  height: 2rem;
  margin-top: -0.1em;
}

.question-mark-nav {
  margin-top: 0;
  height: 1.5rem;
}


.content-container {
  margin: 0 7vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 2rem;
}


.add-new-measurement {
  font-weight: bold;
  -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #f9f9f9;
  color: #242e66;
  padding: 16px 16px !important;
  text-align: center;
  font-size: 0.875rem;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #f4f4f4;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: #242e66;
}

.new-measurement-title {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

div.measurement-wrapper {
  padding-bottom: 7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-selected-device {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 0;
  padding-left: 2vw;
  width: 5rem;
}


.chart-wrapper {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.new-measurement {
  padding-top: 1vh;
}


.new-measurement-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-grid-row-align: center;
  align-self: center;
}

.shadow {
  -webkit-box-shadow: 10px 10px 30px 0 rgba(36, 46, 102, 0.16) !important;
  box-shadow: 10px 10px 30px 0 rgba(36, 46, 102, 0.16) !important;
}


.mat-progress-bar .mat-progress-bar-background {
  fill: #cbcbcb
}

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: #cbcbcb
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: #0089ff
}


.icon-size-1 {
  height: 2.1rem !important;
  margin-top: 8% !important;
}

.icon-size-2 {
  height: 2rem !important;
  width: 2rem !important;
}

.icon-size-3 {
  height: 3rem !important;
  width: 3rem !important;
}

.icon-size-4 {
  height: 1.9rem !important;
  margin-top: 12% !important;
  margin-bottom: 1rem !important;
}


.ngx-timepicker {
  height: 3.5em !important;
  border-radius: 10px;
  width: 200px;
  -webkit-box-shadow: 10px 10px 30px 0 rgba(36, 46, 102, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(36, 46, 102, 0.16);
  background-color: #fff;
}

.ngx-timepicker-control {
  position: relative !important;
  width: 60px !important;
  box-sizing: border-box !important;
}

.ngx-timepicker__control--first {
  padding: 9px 0 0 0;
}

.ngx-timepicker__control--third {
  padding: 9px 0 0 0;
}


.ngx-timepicker-control__arrows {


  display: none !important;
}

.ngx-timepicker__time-colon {
  margin-left: 10px;
  padding: 0.5em 0.3em 0 0 !important;
}

.ngx-timepicker-control__input {
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 1rem;
  color: inherit;
  outline: 0;
  text-align: center;
}

.ngx-timepicker__control,
.ngx-timepicker__toggle {

  padding-top: 0.4em !important;
}

.timepicker-dial__time {
  line-height: inherit !important;
}

.timepicker__header {
  padding: 5% 15% 0 15% !important;
  background-color: #242e66 !important;
}

.timepicker-button {
  color: #242e66 !important;
}

.clock-face__number {
  color: #242e66 !important;
}

.clock-face__number > span.active {
  background-color: #242e66 !important;
}

.clock-face__clock-hand {
  background-color: #242e66 !important;
}

.clock-face__clock-hand_minute:before {
  border: 4px solid #242e66 !important;
}

.timepicker-dial__control:focus-visible {
  outline: 0 !important;
}

.ngx-timepicker-control--active:after {
  background-color: #242e66 !important;
}


.add-measurement-label {
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 5% 0 0 0;
}

input.mat-input-element {
  text-indent: 1em;
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  margin-top: 0 !important;
  padding-top: 0.4em;
}


.add-measurement-input {
  width: inherit !important;
  position: absolute !important;
  left: 0;
}


.add-device-name {
  width: 100% !important;
  text-indent: 0.4rem !important;
}

label {
  width: inherit;
  height: inherit;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
  border-radius: 10px;
  height: 3.5rem;
}

.mat-form-field {
  height: 3.5rem;
  border-radius: 10px;
}

.mat-hint {
  color: #242e66;
  opacity: 0.7;
  font-size: 0.8125rem;
}

.mat-form-field-flex {
  height: inherit;
  border-radius: 10px;
}

.mat-form-field-infix {
  border: 0 !important;
}

.mat-error {
  color: var(--light-red) !important;
  font-size: 0.8125rem !important;
  height: 0;
  font-weight: bold;
  display: contents !important;
  box-sizing: border-box !important;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: var(--light-red) !important;
}

.mat-form-field-subscript-wrapper {
  font-size: 0.8125rem !important;
  top: 110% !important;
  width: 120% !important;
  text-align: right !important;
  overflow: inherit !important;
}

.rounder-input {
  border-radius: 20px;
}

.mat-mdc-form-field-infix {
  width: inherit !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 0 !important;
  height: 3.5rem;
}

.number-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  margin: 1.3rem 0 0 3.5rem;
  font-weight: normal !important;
}

.number-label-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 5%;
  font-weight: normal !important;
  background: white;
}


.small-input {
  width: 6rem !important;
  font-size: 1rem;
}

.bar-big {
  color: slategrey !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  font-size: 30px !important;
  width: 10px;
}

.bar-small {
  color: slategrey !important;
  font-size: 16px !important;
  width: 10px;
  padding: 30px 0 0 0 !important;
}

.bar-small2 {
  color: red !important;
  font-size: 16px !important;
  width: 10px;
  padding: 30px 0 0 0 !important;
}

.red-indicator {
  border: 2px solid #ff0101 !important;
  border-radius: 100px !important;
  position: relative !important;
  margin: 17px 51% -55px 49% !important;
  padding: 34px 1px 0 0 !important;
  background-color: #ff0101;
  z-index: 9 !important;
  height: inherit !important;
  user-select: none;
  pointer-events: none;
}

.show-label {
  font-size: 12px !important;
  width: 10px;
  padding-top: 5px;

}

.both {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}


.mat-select-placeholder {
  color: #746f70 !important;
}

.mat-select-arrow {
  color: #242e66 !important;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: var(--light-red) !important;
}

.mat-form-field .mat-select.mat-select-invalid {
  border: 0.1rem solid var(--light-red) !important;
  border-radius: 10px !important;
  height: inherit;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16);
}

.mat-select {
  padding: 0 20px;
  border-radius: 10px;
  height: 3.5rem !important;
}

.mat-select-value {
  color: #242e66;
  vertical-align: middle;
  padding-top: 10px;
}

.mat-select-trigger {
  height: inherit;
}

.mat-option:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}

.mat-option[aria-disabled=true] {
  opacity: 0.3;
}

.mat-option {
  color: #242e66 !important;
}

.mat-select-panel {
  background: #fff !important;
}

.mat-option.mat-active,
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #242e66 !important;
  opacity: 0.3 !important;
  color: #fff !important;
}


.big-green {
  background-color: var(--accent-green) !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  width: 50vw !important;
  max-width: 14rem;
  border-radius: 100px !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(60, 196, 85, 0.5);
  color: rgba(255, 255, 255, .6);
}

.feeling-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.feeling-button {
  background-color: #fff !important;
  width: 6.125rem !important;
  height: 3.625rem !important;
  border-radius: 10px !important;
  margin: 7px !important;
  box-shadow: 0 0 15px 0 rgba(126, 127, 128, 0.5) !important;
}

.feeling-button .mdc-button__label {
  padding: 0 !important;
}

.mdc-button__label {
  transform: translateY(0.35rem);
}

.selected-feeling {
  border: 3px solid #242e66 !important;
  -webkit-transition: 0.1s !important;
  -o-transition: 0.1s !important;
  transition: 0.1s !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(126, 127, 128, 0.5) !important;
  box-shadow: 0 0 15px 0 rgba(126, 127, 128, 0.5) !important;
}

.mat-flat-button,
.mat-raised-button,
.mat-mini-fab {
  -webkit-box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16) !important;
  box-shadow: 10px 10px 30px 0 rgba(126, 127, 128, 0.16) !important;
}

.feeling-size {
  width: 3rem;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 5%;
}

.delete-measurement {
  border-radius: 100px !important;
  font-size: 1rem;
  font-weight: bold;
}

.spinner-center {
  padding: 0 2%;
  margin: -6px 0 0 0;
}

.spinner-center .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #242e66 !important;
}


.leader-line {
  position: absolute !important;
  white-space: nowrap;
  z-index: 2;
  overflow: hidden !important;
  -ms-overflow-style: none;

  scrollbar-width: none;

}


.container-latest-measurement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 5%;
}

.container-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 5%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-item {

  -webkit-box-flex: 1;
  -ms-flex: 1 0 45%;
  flex: 1 0 45%;
  margin: 5px;
  text-align: center;
  align-self: flex-end;
}

.flex-row-item {

  -webkit-box-flex: 1;
  -ms-flex: 1 0 45%;
  flex: 1 0 45%;
  margin: 5px;
}

.flex-item-full {

  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  margin: 5px;
  text-align: left;
}

.flex-row-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.add-new-data {
  background: #ffffff !important;
}


.add-new-data {
  background: #ffffff !important;
}

.mat-datepicker-content {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #ffffff !important;
  color: #242e66 !important;
}

.general-wrapper {
  margin: 0 5%;
  padding-top: 1rem;
}


[app-disabled] {
  opacity: 0.97;
  pointer-events: none;
}


.tooltip {
  background-color: #ffffff !important;
  color: #242e66 !important;
  max-width: 50vw !important;
  padding: 9px 8px !important;
}

.tooltip-show {
  opacity: 0.95 !important;
}

.tooltip-top:after {
  border-color: #fff transparent transparent !important;
  border-width: 10px !important;
  margin-left: -10px !important;
}

.tooltip-shadow {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
}

.tooltip-right:after {
  border-color: transparent #fff transparent transparent !important;
  border-width: 10px !important;
  margin-top: -10px !important;
  right: 100%;
  top: 50%;
}

.tooltip-left:after {
  border-color: transparent transparent transparent #fff !important;
  border-width: 10px !important;
  left: 100% !important;
  margin-top: -10px !important;
  top: 50% !important;
}

.dairy-nav-bar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.diary-nav-bar-title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  -webkit-box-flex: 10;
}

.diary-nav-bar-close {
  -webkit-box-flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-grid-row-align: center;
}

textarea.cdk-textarea-autosize {
  border-radius: 10px !important;
  vertical-align: bottom !important;
  line-height: 1.5 !important;
  background: white !important;
}


.mat-slide-toggle-bar {
  background-color: #242e66 !important;
  height: 3px !important;
  width: 28px !important;
}

.mat-slide-toggle {
  padding: 0 5px !important;
}

.mat-slide-toggle-thumb {
  height: 13px !important;
  width: 13px !important;
  background-color: #aaadbe !important;
}

.mat-slide-toggle-thumb-container {
  width: 13px !important;
  height: 13px !important;
  top: -5px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1dd56a !important;
}

.red-remove-icon-trash-bin {
  background: rgb(217 0 82) !important;
  box-shadow: inset 0 0 0 1px rgb(255 255 255) !important;
  border-radius: 10px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  margin: 18px 0 !important;
}

.delete-trash-obs-button {
  height: 4.2rem !important;
  align-items: center !important;
  min-height: 4.2rem !important;
  padding: 0 34px !important;
  background: rgb(217 0 82) !important;
  border-radius: 10px !important;
  position: absolute !important;
  width: 100px !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-self: center !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.red-remove-container {
  display: flex;
  justify-content: flex-end;
}

.container-measurements {
  width: 100%;
}


.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}


.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--light-red) !important;
}

.mat-form-field-underline {
  display: none !important;
}

.start-test {
  height: 1.5rem;
  border-radius: 10px;
  color: #242e66;
  background-color: rgba(36, 46, 102, 0.05);
  text-align: center;
  padding: 0.3rem 2rem;
  display: flex;
  align-self: flex-end;
}

.box-shadow {
  box-shadow: 0 0 10px 0.2rem rgb(36 46 102 / 16%) !important
}

.nav-circle-button {
  background: #ededed !important;
}

.nav-button-blue {
  background-color: rgb(36, 46, 102) !important;
}

.select-to-whom {
  height: 4.8rem !important;
  border-radius: 10px !important;
  box-shadow: 10px 10px 30px 0 hsl(210deg 1% 50% / 16%) !important;
  background-color: #fff !important;
}

.select-to-whom:focus {
  outline: 2px solid black !important;
  outline-offset: 2px !important;
}


.dark-shadow-button {
  box-shadow: 0 0 10px 0.2rem rgb(36 46 102 / 16%) !important;
}

.text-button-whom {
  font-size: 0.9rem;
  font-weight: normal;
  color: #242e66;
  line-height: 2;
}

.input-radius-10 {
  border-radius: 10px !important;
}

.red-text {
  color: var(--light-red);
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-90 {
  width: 90% !important;
}

.w-70 {
  width: 70% !important;
}

.w-9rem {
  width: 9rem;
}

.header-height-padding {
  padding-top: var(--header-height);
}

.login-content-container {
  display: flex;
}

.login-logo-container {
  flex: 0 0 auto;
  width: 100px;
  padding: 10px 0;
  text-align: center;
}

.login-logo-container.extended-padding {
  padding: 20px 0;
}

.login-icon {
  width: 3rem;
  height: 3rem;
  user-select: none;
}

.login-text-container {
  flex: 1 1 auto;
  max-width: 100%;
  padding: 0 40px 0 0;
}

.login-text-heading {
  font-size: 1.5rem;
  line-height: 1.22;
  letter-spacing: -0.27px
}

.login-instructions {
  font-size: 16px;
}

.error-msg-login-container {
  position: absolute;
  right: 0;
  top: 100%;
}

.auth-form-inputs {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 1rem;
  align-items: center;
}

.login-header {
  gap: 0.5rem;
}

.title-font-styles {
  font-size: 1rem;
  line-height: 1.22;
  letter-spacing: -0.27px;
}

.desktop-form-control {
  height: 3em;
}

.white-bg {
  background-color: #fff;
}

.opaque-white-bg {
  background-color: var(--opaque-white);
}

.box-shadow {
  box-shadow: 10px 10px 30px 0 rgb(126 127 128 / 16%);
}

.faded-box-shadow {
  box-shadow: 10px 10px 60px 0 rgba(36, 46, 102, 0.16);
}

::-webkit-scrollbar {
  width: 10px;

  height: 5px;

  display: block !important;
  padding: 0 10px;
  transition: opacity 0.25s linear;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
}


::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
}


.three-col-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}


.two-col-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}

.desktop-mot-goal {
  width: 50%;
  margin: auto;
}

.mobile-container {
  width: 100%;
  padding: 0 8vw;
}

.desktop-container .mat-button {
  border-radius: 10px;
}

.desktop-container {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.desktop-middle-container {
  width: var(--shaded-area-width);

  min-height: 100vh;
  padding-top: var(--header-height);
  background-color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 10px 10px 60px 0 rgba(36, 46, 102, 0.16);
}

.desktop-width {
  width: 100%;
  max-width: 550px;
}

.wide-desktop-width {
  width: var(--shaded-area-width) !important;
  min-width: var(--shaded-area-min-width) !important;
}

.min-height-100vh {
  min-height: 100vh;
}

.transform-12 {
  transform: scale(1.4);
}

.transform-12smaller {
  transform: scale(0.8);
}

.transform-12bigger {
  transform: scale(1.1);
}

.text-green {
  color: #278614;
}

.width-1-5rem {
  width: 1.5rem;
}

.border-radius-10 {
  border-radius: 10px;
}

.text-justify {
  text-align: justify;

}

.font-07 {
  font-size: 0.7rem !important;
}

.font-1 {
  font-size: 1rem !important;
}

.font-1-2 {
  font-size: 1.2rem !important;
}

.font-1-8 {
  font-size: 1.8rem !important;
}

.h-20 {
  height: 20% !important;
}

.h-70vh {
  height: 70vh !important;
}

.h-60vh {
  height: 60vh !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-6rem {
  height: 6rem;
}

.h-4rem {
  height: 4rem;
}

.pb-6rem {
  padding-bottom: 6rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-05 {
  gap: 0.5rem;
}

app-combo-echart {
  height: 100%;
  width: 100%;
}

.chart-main-class {
  height: 100% !important;
  min-height: 200px !important;
  width: 100% !important;
  z-index: 2;
}

.measurement-accordion-container,
.bp-accordion-container {
  gap: 2rem;
}

.measurement-accordion-container .mat-expansion-panel-body {
  height: 30vh;
}

.bp-accordion-container .mat-expansion-panel-body {
  height: 45vh;
}

.four-row-grid {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.header-icon-container .header-img {
  cursor: pointer;
}

.margin-auto {
  margin: auto;
}

@media screen and (max-width: 1760px) {
  .measurement-accordion-container .mat-expansion-panel-body {
    height: 33vh;
  }

  .bp-accordion-container .mat-expansion-panel-body {
    height: 50vh;
  }
}

@media screen and (max-width: 1600px) {
  .three-col-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }

  .two-col-grid {
    display: flex;
    flex-direction: column;
  }

  .measurement-accordion-container .mat-expansion-panel-body {
    height: 38vh;
  }

  .bp-accordion-container .mat-expansion-panel-body {
    height: 55vh;
  }

}

@media screen and (max-width: 1400px) {
  .measurement-accordion-container .mat-expansion-panel-body {
    height: 45vh;
  }

  .bp-accordion-container .mat-expansion-panel-body {
    height: 68vh;
  }
}

@media screen and (max-width: 1200px) {
  .three-col-grid {
    display: flex;
    flex-direction: column;
  }

  .desktop-mot-goal {
    width: 100%;
  }

  .measurement-accordion-container .mat-expansion-panel-body {
    height: 52vh;
  }

  .bp-accordion-container .mat-expansion-panel-body {
    height: 78vh;
  }
}

@media screen and (max-width: 1000px) {
  .measurement-accordion-container .mat-expansion-panel-body {
    height: 58vh;
  }

  .bp-accordion-container .mat-expansion-panel-body {
    height: 85vh;
  }
}

.mobile-input-box-width {
  width: 71vw !important;
}

.desktop-input-box-width {
  width: 21vw !important;
}

.iphone-font {
  font-family: "Asap", sans-serif;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}


.mat-mdc-form-field {
  display: inline-block !important;
  position: relative !important;
  text-align: left !important;
  line-height: normal !important;
  color: #242e66 !important;
  height: 100% !important;
  width: 100% !important;
}

.mat-mdc-text-field-wrapper {
  background: transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--mdc-filled-text-field-container-color);
}


.mdc-line-ripple {
  display: none !important;
}

.mat-mdc-error, .mat-mdc-form-field-error {
  color: var(--light-red) !important;
  font-size: .8125rem !important;
  height: 0 !important;
  font-weight: 700 !important;
  display: contents !important;
  box-sizing: border-box !important;
}

.mat-hint, .mat-mdc-hint, .mat-mdc-form-field-hint, .mat-mdc-form-field-bottom-align, .mat-mdc-form-field-hint-end {
  color: #242e66;
  opacity: .7 !important;
  font-size: .8125rem !important;
}

.mat-mdc-select {
  height: 100%;
}

.mat-mdc-select-trigger {
  height: 100%;
}

.mat-mdc-select-value {
  padding: 10px 0 0 0 !important;
  color: var(--main-text-color);
}

.mat-mdc-option.mdc-list-item--disabled {
  cursor: default;
  pointer-events: none;
  background-color: var(--main-text-color) !important;
  opacity: .3 !important;
  color: #fff !important;
}

.mat-mdc-select-arrow {
  width: 10px;
  height: 5px;
  position: relative;
  margin-right: 5px;
  color: var(--main-text-color) !important;
}

.mat-mdc-select {
  padding: 0 12px !important;
  border-radius: 10px !important;
  height: 3.5em !important;
}

.mat-mdc-form-field {
  border-radius: 10px !important;
  height: 3.5em !important;
}

.mat-mdc-text-field-wrapper {
  width: 100%;
  height: 100%;
}

.mdc-text-field--filled {
  padding: 0 !important;
}

.mat-mdc-input-element {
  margin: 0 !important;
  width: 100% !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0 !important;
}

.mat-mdc-button:not(:disabled) {
  color: var(--main-text-color, inherit) !important;
}

.mat-mdc-outlined-button > .mat-icon {
  margin: 1px 0 0 0 !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 0 !important;
}


.clear-button {
  border: none;
  background: transparent;
}

.no-outline {
  outline: none;
}


.yellow-outline:focus {
  outline: 2px solid #d29a03;
  outline-offset: 2px;
}

.white-outline:focus {
  outline: 2px solid #ffffff;
  outline-offset: 2px;
}

.black-outline:focus, .black-outline:checked {
  outline: 2px solid black !important;
  outline-offset: 2px;
}

.black-outline-inside:focus {
  outline: 2px solid black !important;
  outline-offset: -3px;
}

.mat-icon-button:focus {
  outline: 2px solid black !important;
  outline-offset: 2px;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}


.mat-mdc-form-field {
  display: inline-block !important;
  position: relative !important;
  text-align: left !important;
  line-height: normal !important;
  color: #242e66 !important;
  height: 100% !important;
  width: 100% !important;
}

.mat-mdc-text-field-wrapper {
  background: transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--mdc-filled-text-field-container-color);
}

input.mat-mdc-input-element {
  text-indent: 1em !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  margin-top: 0 !important;
  font-size: 1rem !important;
}

.mdc-line-ripple {
  display: none !important;
}

.mat-mdc-error, .mat-mdc-form-field-error {
  color: var(--light-red) !important;
  font-size: .8125rem !important;
  height: 0 !important;
  font-weight: 700 !important;
  display: contents !important;
  box-sizing: border-box !important;
}

.mat-hint, .mat-mdc-hint, .mat-mdc-form-field-hint, .mat-mdc-form-field-bottom-align, .mat-mdc-form-field-hint-end {
  color: #242e66;
  opacity: .7 !important;
  font-size: .8125rem !important;
}

.mat-mdc-select {
  height: 100%;
}

.mat-mdc-select-trigger {
  height: 100%;
}

.mat-mdc-select-value {
  padding: 10px 0 0 0 !important;
  color: var(--main-text-color);
}

.mat-mdc-option.mdc-list-item--disabled {
  cursor: default;
  pointer-events: none;
  background-color: var(--main-text-color) !important;
  opacity: .3 !important;
  color: #fff !important;
}

.mat-mdc-select-arrow {
  width: 10px;
  height: 5px;
  position: relative;
  margin-right: 5px;
  color: var(--main-text-color) !important;
}

.mat-mdc-select {
  border-radius: 10px !important;
  height: 3.5em !important;
}

.mat-mdc-form-field {
  border-radius: 10px !important;
  height: 3.5em !important;
}

.mat-mdc-text-field-wrapper {
  width: 100%;
  height: 100%;
}

.mdc-text-field--filled {
  padding: 0 !important;
}

.mat-mdc-input-element {
  margin: -16px 0 0 0 !important;
  width: 100% !important;
}


.mat-mdc-icon-button.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 30px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 !important;
}

.small-input .mat-mdc-form-field-infix {
  width: 6rem !important;
}

.mat-mdc-select-placeholder {
  font-size: var(--text-size-normal) !important;
}


.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden !important;
}

.info-sheet .mat-bottom-sheet-container {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 1rem !important;
}

.info-header-title {
  font-size: var(--text-size-large);
  color: var(--main-text-color);
  font-weight: bold;
}

.vh-50 {
  min-height: 50vh;
}

.vh-20 {
  min-height: 20vh;
}

.line-height-25 {
  line-height: 2.5rem;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-flex {
  background-color: white !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: var(--input-border-radius) !important;
}

.mat-mdc-form-field-flex {
  border-radius: var(--input-border-radius) !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}

.mat-mdc-form-field-flex {
  background: white !important;
}

.calendar-placeholder {
  top: 60% !important;
  color: #746f70 !important;
}

.mat-mdc-unelevated-button.mat-unthemed {
  color: #fff !important;
}

.mat-hint, .mat-mdc-hint, .mat-mdc-form-field-hint, .mat-mdc-form-field-bottom-align, .mat-mdc-form-field-hint-end {
  opacity: 1 !important;
}


.mat-calendar-body-cell-content {
  padding: 8px 0 0 0 !important;
}

.mat-calendar-body {
  font-size: 1rem !important;
}

.mat-calendar-table-header th {
  color: #242e66 !important;
  font-weight: 700 !important;
  font-size: 1.125rem !important;
  padding: 0 !important;
}

app-chart-modal {
  width: 100vw !important;
  display: inline-block !important;
  height: 80vh !important;
}

.mat-mdc-select-value-text {
  font-size: 1rem !important;
}


.pointer-events-none, .pointer-events-none > d, .pointer-events-none > div, .pointer-events-none > drag-scroll > div {
  pointer-events: none !important;
}


.mat-calendar-controls .mdc-button .mdc-button__label {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.mat-datepicker-0-backdrop {
  opacity: 0.5;
  background-color: #0000004d !important;
}

.calendar-important > .mat-calendar-body-cell-content {
  border-radius: 100% !important;
  box-shadow: inset 0 0 0 0.2rem #242e66 !important;
}

.mat-calendar-body-today {
  border-radius: 40px !important;
  color: white !important;
  box-shadow: inset 0 0 0 2rem #242e66 !important;
}

.mat-calendar-body-label {
  color: transparent !important;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.cdk-overlay-pane {
  justify-content: center !important;
}

.report-activity-row > mat-form-field > .mat-mdc-text-field-wrapper {
  height: inherit !important;
}

.space::after {
  content: ' ';
}

.space-before::before {
  content: ' ';
}

.consent-text {
  text-align: justify;
}

.max-height-90 {
  max-height: 90vh;
}

.cookie-modal-wrapper {
  color: white;
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: var(--accent-green) !important;
}


.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: var(--accent-green) 50 !important;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--accent-green) !important;
}

.add-device-name {
  height: 3rem !important;
}

.mat-mdc-form-field-infix {
  width: auto !important;
}

.mat-mdc-select-invalid {
  border: 1px solid var(--error-color) !important;
}

app-datepicker .mat-icon {
  height: 30px !important;
  width: 30px !important;
}

.activity-panel .mat-icon {
  height: 24px !important;
  width: 24px !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  margin: 0.4rem -1px 0 0 !important;
}

.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: var(--form-input-height) !important;
}

.shift-content {
  position: relative;
  top: 1.7em;
  transition: top 0.3s ease;
}

.calendar .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.report-activity-row .mat-mdc-form-field-flex {
  margin: -4px 0 0 0 !important;
}

app-datepicker .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  padding: 0 2px !important;
  opacity: 1 !important;
  transform: translateY(0%) !important;
  display: flex !important;
}

.mat-mdc-form-field-subscript-wrapper {
  width: 161% !important;
}

.max-width-8-rem {
  max-width: 8rem !important;
}

.btn-secondary {
  background-color: white !important;
  border: 2px solid #242e66 !important;
  color: #242e66 !important;
}

/* app-button .mdc-button__label mat-icon {
    margin: 4px 0 0 0;
} */

app-bottom-sheet .mdc-button__label {
  padding: 6px 0 0 0 !important;
}

/* app-bottom-sheet .mdc-button__label mat-icon {
  margin: 4px 0 0 0;
} */

app-button {
  display: flex;
}

.button-egp-primary {
  width: 100% !important;
  max-width: 16rem !important;
  color: white !important;
  height: 3rem !important;

  .mdc-button__label {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

.loading-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots {
  width: 8%;
  aspect-ratio: 5;
  background: radial-gradient(circle closest-side, #ffffff 90%, #0000) 0/calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: d1 1s steps(4) infinite;
}

.narrow-loading-dots {
  width: 5%;
}

@keyframes d1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}

.underline {
  text-decoration: underline;
}

.bottom-modal {
  text-align: justify;
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  color: #242e66;
}

.bottom-modal-body {
  width: 100%;
  max-height: 80vh;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  overflow: auto;
  transition: max-height 0.5s ease-out,
  overflow 0.5s ease-out;
}

.bottom-modal-img-container {
  text-align: center;
}

.bottom-modal-img {
  height: 2.875rem;
  width: 2.875rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.bottom-modal-btn-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  padding-right: 15vw;
  padding-left: 15vw;
}

.bottom-modal-btn-container-mobile {
  flex-direction: column;
  align-items: center;
}

.bottom-modal-btn {
  display: block;
  height: 2.6rem;
  line-height: normal;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100px;
}

.bottom-modal-not-accept-btn {
  min-width: 12rem;
  border: 2px solid #242e66;
  color: #242e66;
}

.bottom-modal-not-accept-btn:hover {
  background-color: white;
  border: 2px solid #242e66;
}

.bottom-modal-remove-btn {
  min-width: 13rem;
  color: white;
  background-color: #d90052;
  border: transparent;
}

.bottom-modal-remove-btn:hover {
  background-color: #d90052;
  color: white;
}

.bottom-modal-remove-btn:active {
  background-color: darkred !important;
  color: white !important;
}

.mobile-css {
  text-align: justify;
  font-size: small !important;
}

.desktop-css {
  text-align: justify;
  width: 70% !important;
  margin: auto;
  border-radius: 10px;
  min-width: var(--shaded-area-min-width) !important;
}

.font-size-090 {
  font-size: 0.9em;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding-top: 8px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: inherit!important;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #2f9d43!important;
  background-color: #2f9d43!important;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  stroke: white!important;
}

.mdc-checkbox__native-control:hover ~ .mdc-checkbox__background {
  background-color: #dce8d4 !important;
}

.mdc-checkbox__background {
  width: 25px!important;
  height: 25px!important;
  border: 2px solid currentColor!important;
  border-radius: 50%!important;
}

.mdc-checkbox {
  padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 11px) / 2) !important;
}

.mdc-checkbox__checkmark {
  top: 3px!important;
  right: 0!important;
  bottom: 0!important;
  left: 3px!important;
  width: 75%!important;
}

@media (max-width: 768px) {
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding-left: 6px!important;
    padding-right: 5px!important;
  }
}

.status-good {
  padding: 0 9px !important;
  align-items: end !important;
  min-height: 28px !important;
  min-width: 28px !important;
  white-space: nowrap;
  background: #effff2 !important;
  color: var(--accent-green) !important;
}

.status-bad {
  padding: 0 9px !important;
  align-items: end !important;
  min-height: 28px !important;
  min-width: 28px !important;
  white-space: nowrap;
  background: #fbe5ed !important;
  color: #d90052 !important;
}

.status-orange {
  padding: 0 9px !important;
  align-items: end !important;
  min-height: 28px !important;
  min-width: 28px !important;
  white-space: nowrap;
  background: #fbf3e5 !important;
  color: #d97400 !important;
}

.chart-dialog .mat-mdc-dialog-surface {
  overflow-x: hidden;
}

.notification-info {
  background: rgba(0, 95, 175, 0.08); /* Default background */
  border-radius: 10px;
  position: relative;
  margin-bottom: 0.9rem;
  width: 77vw;
  transition: background-color 0.3s ease;
}

.status-text {
  font-weight: 400; /* Normal weight for status */
}

.status-new, .status-pending {
  background: rgba(0, 95, 175, 0.1);
}

.status-in-progress, .status-ongoing {
  background: rgba(255, 165, 0, 0.1);
}

.status-completed, .status-done {
  background: rgba(0, 200, 0, 0.1);
}

.status-cancelled {
  background: rgba(197, 20, 20, 0.78);
}

@media screen and (max-width: 380px) {
  .notification-info {
    width: 280px;
  }
}
@media screen and (min-width: 760px) {
  .notification-info {
    width: 540px;
  }
}
@media screen and (min-width: 960px) {
  .notification-info {
    width: 650px;
  }
}

.notification-title {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0 1.5rem;
  font-size: 0.83rem;
  width: 100%;
}

.notification-close-button {
  position: absolute;
  right: 1%;
  top: 15%;
  cursor: pointer;
}

.title-row {
  display: flex;
  width: 100%;
}

.message-type {
  font-weight: 700; /* Bold for message type */
}

.status-text {
  font-weight: 400; /* Normal weight for status */
}

.status-separator {
  margin: 0 0.5rem;
  font-weight: 400; /* Normal weight for separator */
}

.notification-close-button {
  margin-left: auto;
}

.custom-dialog-container {
  border-radius: 10px !important;
  overflow: hidden !important;
  background-color: transparent !important;
}

